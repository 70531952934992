<template>
  <v-container fluid style="background-color:#f2f2f2 !important;min-height:100%;">
    <!-- Filter Array -->
    <v-row
      class="ma-0 pa-0 px-2 mb-2"
      style="background-color:white !important;border: 1px solid teal;border-radius: 6px;"
    >
      <!-- date picker -->
      <v-col cols="12" class="ma-0 pa-0 py-2 mr-2">
        <v-card flat style="background-color:transparent;">
          <v-card-title class="pa-0">
            <date-range-picker @dateChange="onDateChange" initDateMode="thisWeek"/>
            <v-spacer/>
            <v-btn
              class="ma-2 pa-1 px-2"
              outlined
              @click="showCalendar = !showCalendar"
              :color="showCalendar ? '#AD1457' : 'grey'"
              min-width="0"
            >
              <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>

            <div
              :class="{
                'ml-0 mt-0': $vuetify.breakpoint.smAndDown,
                'ml-1': $vuetify.breakpoint.mdAndUp,
              }"
              style="width:100%;"
            >
              <filter-array
                :filters="statusList"
                @change-filters="updateFilter"
              ></filter-array>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Filter Array -->

    <v-row>
      <v-col
        :class="{
          'd-none': !allowShowCalendar,
        }"
        cols="12" md="12"
      >
        <mcv-calendar
          :data="displayCalendar"
          :allowShowEvent="true"
        ></mcv-calendar>
      </v-col>
      <v-col
      :class="{
          'd-none': !allowShowTable,
        }"
       cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title class="py-2 px-2 mb-1" dark style="color:teal;">
            <v-btn
              color="teal"
              dark
              class="text-none mr-2 mb-2"
              to="/off-submit-order"
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Xin Nghỉ Phép
            </v-btn>
            <template>
              <v-btn
                color="error"
                light style="color:white;"
                :disabled='!allowCancel'
                class="text-none mr-2 mb-2"
                @click="callCancelSession()"
              >
                <v-icon small class="mr-2">mdi-close-octagon</v-icon>
                Hủy
              </v-btn>
            </template>
            <v-spacer />
            <v-text-field
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              outlined
              dense
              single-line
              hide-details
              style="max-width:320px;"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_sessions"
              :headers="headers"
              :items="sessionsAfterFilter"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có phòng nào"
              item-key="_id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select
              single-select
              dense
              class="elevation-0"
              :search="searchString"
              :page.sync="depsPage"
              @page-count="pageCount = $event"
              hide-default-footer
              :sort-by="['start_time']"
              :sort-desc="[true]"
            >
              <!--
              <template v-slot:item.room="{ item }">
                <div style="cursor:pointer;"
                  @click="gotoSession('/off-order/' + item._id)">
                  {{ item.properties.map(p => p.name).join(', ') }}
                </div>
              </template>
              -->
              <template v-slot:item.start_time="{ item }">
               <div
                  style="cursor:pointer;"
                  @click="gotoSession('/off-sessions/' + item._id)"
                >
                  {{ resolveOfDateDisplay(item) }}
                </div>
              </template>
              <template v-slot:item.props="{ item }">
                {{ getOffOrderStatusName(item.props.offType, item.props.pickedHours)}}
              </template>
              <!-- <template v-slot:item.created_at="{ item }">
                {{ $moment(item.created_at).format("HH:mm DD/MM/YYYY") }}
              </template> -->
              <template v-slot:item.status="{ item }">
                <div style="font-size:12px;font-weight:600;"
                  :style="{'color': getStatusColor(item.status)}">
                  {{getStatusName(item.status)}}
                </div>
              </template>
            </v-data-table>
            <div v-if="pageCount > 1" class="text-center pt-2">
              <v-pagination
                v-model="depsPage"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import mixins_room from "@/mixins/Rooms/index.js";

export default {
  computed: {
    ...mapGetters({
      token: "token",
      userId: "userId",
    }),
    sessionsAfterFilter() {
      let self = this;
      let result = self.sessions;

      // Filter of Time
      if (result.length > 0 && self.range.start > 0) {
        let filter = [];
        filter = result.filter(
          (s) =>
            (this.$moment(this.range.start).valueOf() <=
              this.$moment(s.start_time).valueOf() &&
              this.$moment(s.start_time).valueOf() <=
                this.$moment(this.range.end).valueOf()) ||
            (this.$moment(this.range.start).valueOf() <=
              this.$moment(s.end_time).valueOf() &&
              this.$moment(s.end_time).valueOf() <=
                this.$moment(this.range.end).valueOf())
        );
        result = filter;
      }

      // Filter of Status
      if (result.length > 0) {
        let filter = [];
        filter = result.filter((s) => self.selectedStatus.includes(s.status));
        result = filter;
      }

      return result;
    },
    allowCancel () {
      let allow = true
      if(!this.selected_sessions || this.selected_sessions.length <= 0){
        allow = false
      }
      this.selected_sessions.forEach(session => {
        if(['REJECTED', 'CANCELLED'].includes(session.status)){
          allow = false
        }
      })
      return allow
    },
    allowShowTable() {
      return !this.showCalendar;
    },
    allowShowCalendar() {
      return this.showCalendar;
    },
    displayCalendar() {
      let result = [];
      
      let sessionsAfFilter = this.sessionsAfterFilter;
        if (!sessionsAfFilter || sessionsAfFilter.length <= 0) return [];
        sessionsAfFilter.forEach((item) => {
          let startTime = this.$moment(item.start_time).format(
            "YYYY-MM-DD HH:mm"
          );
          let endTime = this.$moment(item.end_time).format("YYYY-MM-DD HH:mm");
          if (
            this.$moment(item.start_time).valueOf() <
            this.$moment(this.range.start).valueOf()
          ) {
            startTime = this.$moment(this.range.start).format(
              "YYYY-MM-DD HH:mm"
            );
          }
          if (
            this.$moment(item.end_time).valueOf() >
            this.$moment(this.range.end).valueOf()
          ) {
            endTime = this.$moment(this.range.end).format("YYYY-MM-DD HH:mm");
          }

          let content = item.user_name ? item.user_name : "-";
          content = content + " - " + item.description;
          
          //Color
          let color = ""
          let typeObj = this.offTypeList.find( p => p.value == item.props.offType);
          if(!typeObj) color = "#263238"
          else color = typeObj.color;

          result.push({
            data: item,
            name: content,
            start: startTime,
            end: endTime,
            color: color,
            timed: true,
          });
        });

      return result;
    },
  },
  mixins: [mixins_room],
  data() {
    return {
      sessions: [],
      selected_sessions: [],
      properties: [],
      propertiesFilterArr: [],
      departments: [],
      users: [],
      pageSize: 15,
      pageCount: 0,
      depsPage: 1,
      loading: false,
      searchString: "",
      headers: [
        {
          text: "Ngày nghỉ",
          align: "start",
          sortable: true,
          value: "start_time",
          // width: 140,
        },
        {
          text: "Hình thức",
          align: "start",
          sortable: true,
          value: "props",
          width: 140,
        },
        {
          text: "Lý do xin nghỉ",
          align: "start",
          sortable: false,
          value: "description",
          width: 240,
        },
        {
          text: "Trạng Thái",
          align: "center",
          sortable: true,
          value: "status",
          width: 100,
        },
      ],
      offTypeList: [
          {label: 'Nghỉ cả ngày', value:'full-date'},
          {label: 'Nghỉ buổi sáng', value:'off-morning'},
          {label: 'Nghỉ buổi chiều', value:'off-afternoon'},
          {label: 'Làm việc online', value:'work-from-home'},
          {label: 'Nghỉ không lương', value:'full-date-nopayment'},
          {label: 'Nghỉ chế độ: cưới xin-hiếu hỉ...', value:'full-date-legal'},
          {label: 'Vắng mặt', value:'absent'},
          {label: 'Làm việc online', value:'work-from-home'}
      ],
      statusList: [
        { selected: true, code: "WAIT_FOR_APPROVE", label: "Chờ duyệt", color:'orange' },
        { selected: true, code: "APPROVED", label: "Đã duyệt" , color:'green'},
        { selected: false, code: "REJECTED", label: "Đã từ chối" , color:'red'},
        { selected: false, code: "CANCELLED", label: "Đã hủy", color:'#888888'},
      ],
      selectedStatus: [],
      rangeMode: "day",
      range: {
        start: this.$moment()
          .startOf("week")
          .toDate(),
        end: this.$moment()
          .endOf("week")
          .toDate(),
      },
      showCalendar: false,
    };
  },
  methods: {
    gotoSession (path) {
      console.log(path)
      this.$router.push({path: path})
    },
    getStatusName (code) {
      let matchStatus = this.statusList.find(status => status.code == code)
      if(matchStatus) return matchStatus.label
      return ''
    },
    getStatusColor (code) {
      let matchStatus = this.statusList.find(status => status.code == code)
      if(matchStatus) return matchStatus.color
      return 'black'
    },
    onDateChange (dateRange) {
      console.log(dateRange)
      this.range = dateRange.range
      this.rangeMode = dateRange.rangeMode
      this.fetchSessions()
    },
    updateFilter(filters) {
      this.selectedStatus = filters
      // this.fetchSessions()
    },
    resolveOfDateDisplay (rentSession) {
      let mStart = this.$moment(rentSession.start_time).format("DD/MM/YYYY")
      let mEnd = this.$moment(rentSession.end_time).format("DD/MM/YYYY")
      if(mStart != mEnd) {
        return mStart + ' - ' + mEnd
      } else {
        return mStart
      }
    },
    getOffOrderStatusName (code, pickedHours) {
      let matchStatus = this.offTypeList.find(s => s.value == code)
      if (matchStatus) {
        if(matchStatus.value=='absent' && pickedHours){
          let hourLabels = ' '
          pickedHours.forEach(hour => {
            if (hour.value ) {
              hourLabels += hour.hour+'h, '
            }
          })
          return matchStatus.label + hourLabels
        }
        return matchStatus.label
      }
      return ''
    },
    fetchSessions () {
      let self = this
      self.loading = true
      let query = `from_time=${this.range.start.getTime()}&to_time=${this.range.end.getTime()}`
      this.axios.get(
        self.$root.apiAssetMana + `/sessions?type=off-order&limit=10000&users_id=${this.userId}&${query}`,
        { headers: { "x-auth": this.token }}
        ).then(res =>  {
          if(res.data.status == 'OK'){
            self.sessions = res.data.content.items
          }
        }).finally(() => {
          self.loading = false
          // console.log(self.sessions)
        })
    },
    callCancelSession() {
      if (!this.selected_sessions || this.selected_sessions.length <= 0) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiAssetMana + "/sessions/locks",
          {
            ids: this.selected_sessions.map((u) => u._id),
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            alert("Thành công!")
            self.fetchSessions()
          } else {
            alert("Đã xảy ra lỗi!")
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          alert("Đã xảy ra lỗi!")
          console.log(err);
        });
    },
    deleteSessions() {
      if (confirm("Bạn có chắc muốn xóa các mục đã chọn ?")) {
        if (!this.selected_sessions || this.selected_sessions.length <= 0)
          return;
        let self = this;
        this.axios
          .delete(self.$root.apiAssetMana + "/sessions/deletes", {
            headers: { "x-auth": this.token },
            data: {
              ids: this.selected_sessions.map((u) => u._id),
            },
          })
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Xóa thành công!");
              self.fetchSessions()
            } else {
              alert("Đã xảy ra lỗi!")
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            alert("Đã xảy ra lỗi!")
            console.log(err);
          });
      }
    },
    setColorForOffOrder() {
      let self = this;
      self.offTypeList.forEach((property, idx) => {
        if (self.roomsColorArr[idx]) {
          property.color = self.roomsColorArr[idx];
        } else {
          property.color = "#263238";
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setColorForOffOrder()
    })
  },
};
</script>

<style></style>
